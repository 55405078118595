@import 'theme.scss';

.fred-table {
    border-spacing: 0;
    border-collapse: collapse;

    font-family: Roboto, "Helvetica Neue", sans-serif;

    thead {
        tr {
            height: 40px;

            th {
                color: rgba(0, 0, 0, 0.54);
                font-size: 12px;
                font-weight: 500;
                padding: 0 8px;
                text-align: left;
            }
        }

        tr:last-of-type {
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        }
    }

    tbody {
        tr {
            height: 48px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);

            td {
                color: rgba(0, 0, 0, 0.87);
                font-size: 14px;
                padding: 0 8px;
            }
        }
    }
}


/*.active {
    background: mat-color($sbf-theme-primary);
}*/
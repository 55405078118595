//@import '@angular/material/prebuilt-themes/indigo-pink.css';
@use '@angular/material' as mat;
@import "theme";
@import 'fred.scss';
@import 'toastr/toastr';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();
@include mat.all-component-themes($sbf-theme);

html,
body,
app-root {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

body {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    background-color: #EEEEEE;
}

* {
    box-sizing: border-box;
}

.section {
    display: flex;
    flex-direction: column;
    justify-content: normal;
    padding: 0.5rem;
    height: 100%;

    .header {
        flex: 0 0 auto;
        margin-bottom: 1rem;

        .search-form {
            .mat-mdc-form-field {
                margin-right: .5rem;
            }
        }
    }

    .main {
        flex: 1 1 auto;
        overflow: auto;
        margin-bottom: 1rem;

        .mat-mdc-table {
            width: 100%;

            .mat-column-id {
                margin-right: .2rem;
            }
        }

        .column-form {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
        }

        .row-form {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;

            .mat-mdc-form-field,
            .mat-mdc-checkbox,
            .mat-mdc-slide-toggle {
                margin-right: 1rem;
            }
        }
    }

    .footer {
        flex: 0 0 auto;
    }
}

.mat-mdc-card {
    margin: .5rem;
    padding: .5rem;
}

.rightholder-select {
    max-width: inherit !important;

    button.active,
    button.active:hover {
        background-color: mat.get-color-from-palette($sbf-theme-primary);
        color: white;
    }

    button:hover:not(.active) {
        background-color: mat.get-color-from-palette($sbf-theme-warn);
        color: white;
    }
}

/**
 Style needed for the DialogModule.
 This has to be placed here because of the way dialogs are intialized,
 for further reading see: https://github.com/angular/components/blob/master/guides/customizing-component-styles.md
 */
.dialog-service .mat-mdc-dialog-container {
    padding: 0;

    .dialog {
        mat-toolbar {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
        }

        .dialog-body {
            display: flex;
            flex-direction: row;
            margin: 1rem;
        }

        .dialog-actions {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: .5rem;
        }
    }
}

.dialog-scroll {
    overflow: auto;
}

/*Needs to be in top stylesheet for succesful override*/
.ngx-timepicker {
    border-bottom: 1px solid rgba(0, 0, 0, .42) !important;
}


.mat-expansion-panel-header {
    max-height: 48px;
}

.clickable-list-item>span {
    padding-top: 16px;
    cursor: pointer;
}

.shadow {
    box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
}